<template>
  <div class="VideoList">
    <VideoMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="deviceDataOpen"
        >添加录像机</el-button
      >
    </div>

    <!-- NVR列表 -->
    <div class="wrap">
      <el-empty description="暂无数据" v-if="!deviceList.length"></el-empty>
      <el-descriptions
        v-for="(item, index) in deviceList"
        :key="index"
        labelClassName="labelClassName"
        :title="item.name"
        :column="2"
        border
        style="margin-bottom: 26px"
      >
        <template slot="extra">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-video-play"
            plain
            @click="deviceOpen(item)"
            >视频通道</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="small"
            @click="deviceDataEdit(item)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="small"
            @click="deviceDel(item)"
            >删除</el-button
          >
        </template>
        <el-descriptions-item label="appkey">{{
          item.appkey
        }}</el-descriptions-item>
        <el-descriptions-item label="secret">{{
          item.secret
        }}</el-descriptions-item>
        <el-descriptions-item label="序列号">{{
          item.deviceSerial
        }}</el-descriptions-item>
        <el-descriptions-item label="验证码">{{
          item.verificationcode
        }}</el-descriptions-item>
        <el-descriptions-item label="通道数">{{
          item.chanels
        }}</el-descriptions-item>
        <el-descriptions-item label="简介">{{
          item.description
        }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- 视频通道列表 -->
    <el-dialog
      title="视频通道"
      :visible.sync="deviceShow"
      width="1000px"
      :before-close="deviceClose"
    >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="channelAdd"
        >添加视频通道</el-button
      >
      <el-table :data="channelList" style="width: 100%" @row-click="playerOpen">
        <el-table-column prop="videoName" label="视频名称"> </el-table-column>
        <el-table-column prop="serialNumber" label="序列号" width="180">
        </el-table-column>
        <el-table-column prop="chanelNumber" label="通道号" width="180">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              @click.stop="channelEdit(scope.row)"
              size="mini"
              plain
              type="primary"
              icon="el-icon-edit"
            >
              编辑
            </el-button>
            <el-button
              @click.stop="channelDel(scope.row)"
              size="mini"
              plain
              type="danger"
              icon="el-icon-delete"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span style="line-height: 50px">共 {{ channelList.length }} 条</span>
    </el-dialog>

    <!-- 视频播放器 -->
    <el-dialog title="实时视频" :visible.sync="playerShow" width="808px">
      <div id="video-container"></div>
    </el-dialog>

    <!-- 添加或编辑录像机信息 -->
    <el-dialog
      title="录像机"
      :visible.sync="deviceDataShow"
      :before-close="deviceDataClose"
      width="808px"
    >
      <el-descriptions
        title="设备信息"
        labelClassName="labelClassName"
        :column="1"
        border
      >
        <template slot="extra">
          <el-button
            type="primary"
            icon="el-icon-check"
            size="small"
            @click="deviceDataSave"
            >保存</el-button
          >
        </template>
        <el-descriptions-item label="设备名称">
          <el-input
            v-model="deviceData.name"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="appkey">
          <el-input
            v-model="deviceData.appkey"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="secret">
          <el-input
            v-model="deviceData.secret"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="序列号">
          <el-input
            v-model="deviceData.deviceSerial"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="验证码">
          <el-input
            v-model="deviceData.verificationcode"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="通道数">
          <el-input
            v-model="deviceData.chanels"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="简介">
          <el-input
            v-model="deviceData.description"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>

    <!-- 添加或编辑视频通道信息 -->
    <el-dialog
      title="视频通道"
      :visible.sync="channelDataShow"
      :before-close="channelDataClose"
      width="808px"
    >
      <el-descriptions
        title="视频通道信息"
        labelClassName="labelClassName"
        :column="1"
        border
      >
        <template slot="extra">
          <el-button
            type="primary"
            icon="el-icon-check"
            size="small"
            @click="channelDataSave"
            >保存</el-button
          >
        </template>
        <el-descriptions-item label="视频名称">
          <el-input
            v-model="channelData.videoName"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="视频地址">
          <el-input
            v-model="channelData.ezopenUrl"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="设备类型">
          <el-input
            v-model="channelData.type"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="序列号">
          <el-input
            v-model="channelData.serialNumber"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="通道号">
          <el-input
            v-model="channelData.chanelNumber"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="AppKey">
          <el-input
            v-model="channelData.appkey"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="secret">
          <el-input
            v-model="channelData.secret"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <el-input
            v-model="channelData.remark"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<style lang="less" scope>
.VideoList {
  .el-form-item {
    margin-bottom: 0;
  }
  #video-container {
    width: 768px;
    height: 432px;
    background-color: #000;
  }
  .labelClassName {
    width: 120px;
  }
}
</style>

<script>
import EZUIKit from "ezuikit-js";
import VideoMenu from "@/components/VideoMenu.vue";
import qs from "qs";

export default {
  name: "VideoList",
  components: {
    VideoMenu,
  },
  data() {
    return {
      projectList: [],
      currentProject: null,

      deviceList: [],

      deviceShow: false,
      currentDevice: null,

      channelList: [],
      currentChannel: null,

      accessToken: null,
      player: null,
      playerShow: false,

      deviceDataShow: false,
      deviceData: {
        id: null,
        projectId: null,
        name: null,
        description: null,
        appkey: null,
        secret: null,
        deviceSerial: null,
        verificationcode: null,
        chanels: null,
      },

      channelDataShow: false,
      channelData: {
        id: null,
        ysappinfoId: null,
        videoName: null,
        ezopenUrl: null,
        type: null,
        serialNumber: null,
        mountSerialNumber: null,
        chanelNumber: null,
        appkey: null,
        secret: null,
        captureInterval: null,
        captureStartTime: null,
        captureEndTime: null,
        installAddr: null,
        remark: null,
        createUser: null,
        createTime: null,
      },
    };
  },
  computed: {},
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.deviceName = "";
      this.serialNumber = "";
      this.getDeviceList(); // 获取设备列表
    },
    currentDevice: function (val) {
      if (val) {
        console.log("currentDevice id =>", val.id);
        this.getDeviceChannel();
      }
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取设备列表数据
    getDeviceList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.currentProject,
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/ysappinfo/queryPage",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getDeviceList=>", res);
          that.deviceList = res.data.ysAppInfoList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开设备
    deviceOpen: function (data) {
      this.currentDevice = data;
      this.deviceShow = true;
    },
    // 关闭设备
    deviceClose: function () {
      this.deviceShow = false;
      this.currentDevice = null;
      this.channelList = [];
      this.accessToken = null;
    },
    // 获取设备所有通道列表
    getDeviceChannel: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: that.currentDevice.id,
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/ysappinfo/getById",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getDeviceChannel =>", res);
          that.loading.close();
          if (res.data.code == 1) {
            that.deviceClose()
            that.$alert(res.data.msg, '录像机链接失败', {
              confirmButtonText: '确定'
            }
          );
            return;
          }
          that.channelList = res.data.ysappVideos;
          that.accessToken = res.data.accessToken;
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开播放器
    playerOpen: function (row) {
      var that = this;
      console.log("playerOpen =>", row);
      this.playerShow = true;
      setTimeout(() => {
        if (!that.player) {
          that.player = new EZUIKit.EZUIKitPlayer({
            id: "video-container", // 视频容器ID
            accessToken: that.accessToken,
            url: row.ezopenUrl,
            template: "simple", // simple-极简版; standard-标准版; security-安防版(预览回放); voice-语音版;
            width: "768px",
            height: "432px",
          });
        } else {
          that.player.stop().then(() => {
            that.player.play({
              accessToken: that.accessToken,
              url: row.ezopenUrl,
            });
          });
        }
      }, 100);
    },
    // 打开编辑/添加录像机窗口
    deviceDataEdit: function (data) {
      this.currentDevice = data;
      this.deviceDataOpen();
    },
    deviceDataOpen: function () {
      if (!this.currentDevice) {
        this.deviceData = {
          id: null,
          projectId: this.currentProject,
          name: null,
          description: null,
          appkey: null,
          secret: null,
          deviceSerial: null,
          verificationcode: null,
          chanels: null,
        };
      } else {
        this.deviceData = JSON.parse(JSON.stringify(this.currentDevice));
      }
      this.deviceDataShow = true;
    },
    // 关闭编辑/添加录像机窗口
    deviceDataClose: function () {
      this.currentDevice = null;
      this.deviceData = {
        id: null,
        projectId: null,
        name: null,
        description: null,
        appkey: null,
        secret: null,
        deviceSerial: null,
        verificationcode: null,
        chanels: null,
      };
      this.deviceDataShow = false;
    },
    // 更新或添加录像机
    deviceDataSave: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = that.deviceData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/ysappinfo/insertOrEditYsAppInfo",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("deviceDataSave =>", res);
          that.deviceDataClose();
          that.getDeviceList();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除录像机
    deviceDel: function (data) {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: data.id,
      };

      that
        .$confirm(
          "此操作将永久删除该录像机及其下挂视频通道, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/ysappinfo/deleteById",
              data: JSON.stringify(data),
            })
            .then((res) => {
              console.log("deviceDel =>", res);
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getDeviceList();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消删除",
          });
          that.loading.close();
        });
    },
    // 添加通道
    channelAdd: function () {
      this.channelDataShow = true;
      this.channelData = {
        id: null,
        ysappinfoId: this.currentDevice.id,
        videoName: null,
        ezopenUrl: null,
        type: null,
        serialNumber: null,
        mountSerialNumber: null,
        chanelNumber: null,
        appkey: null,
        secret: null,
        captureInterval: null,
        captureStartTime: null,
        captureEndTime: null,
        installAddr: null,
        remark: null,
        createUser: null,
        createTime: null,
      };
    },
    // 编辑通道
    channelEdit: function (row) {
      this.channelDataShow = true;
      this.channelData = JSON.parse(JSON.stringify(row));
    },
    // 关闭通道资料窗口
    channelDataClose: function () {
      this.channelDataShow = false;
      this.channelData = {
        id: null,
        ysappinfoId: null,
        videoName: null,
        ezopenUrl: null,
        type: null,
        serialNumber: null,
        mountSerialNumber: null,
        chanelNumber: null,
        appkey: null,
        secret: null,
        captureInterval: null,
        captureStartTime: null,
        captureEndTime: null,
        installAddr: null,
        remark: null,
        createUser: null,
        createTime: null,
      };
    },
    // 删除通道
    channelDel: function (row) {
      console.log("channelDel =>", row);
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: row.id,
      };

      that
        .$confirm(
          "此操作将永久删除该视频通道, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/ysappinfo/video/delVideo",
              data: JSON.stringify(data),
            })
            .then((res) => {
              console.log("deviceDel =>", res);
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getDeviceChannel();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消删除",
          });
          that.loading.close();
        });
    },
    // 保存通道数据
    channelDataSave: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = that.channelData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/ysappinfo/saveOrEditVideo",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("channelDataSave =>", res);
          that.channelDataClose();
          that.getDeviceChannel();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
  },
};
</script>